import(/* webpackMode: "eager", webpackExports: ["Hydrate","useHydrate"] */ "/home/runner/_work/nvs-code/nvs-code/common/temp/node_modules/.pnpm/@tanstack+react-query@4.29.19_biqbaboplfbrettd7655fr4n2y/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/home/runner/_work/nvs-code/nvs-code/common/temp/node_modules/.pnpm/@tanstack+react-query@4.29.19_biqbaboplfbrettd7655fr4n2y/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider","defaultContext","useQueryClient"] */ "/home/runner/_work/nvs-code/nvs-code/common/temp/node_modules/.pnpm/@tanstack+react-query@4.29.19_biqbaboplfbrettd7655fr4n2y/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/home/runner/_work/nvs-code/nvs-code/common/temp/node_modules/.pnpm/@tanstack+react-query@4.29.19_biqbaboplfbrettd7655fr4n2y/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["unstable_batchedUpdates"] */ "/home/runner/_work/nvs-code/nvs-code/common/temp/node_modules/.pnpm/@tanstack+react-query@4.29.19_biqbaboplfbrettd7655fr4n2y/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/home/runner/_work/nvs-code/nvs-code/common/temp/node_modules/.pnpm/@tanstack+react-query@4.29.19_biqbaboplfbrettd7655fr4n2y/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/home/runner/_work/nvs-code/nvs-code/common/temp/node_modules/.pnpm/@tanstack+react-query@4.29.19_biqbaboplfbrettd7655fr4n2y/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating"] */ "/home/runner/_work/nvs-code/nvs-code/common/temp/node_modules/.pnpm/@tanstack+react-query@4.29.19_biqbaboplfbrettd7655fr4n2y/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/home/runner/_work/nvs-code/nvs-code/common/temp/node_modules/.pnpm/@tanstack+react-query@4.29.19_biqbaboplfbrettd7655fr4n2y/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/home/runner/_work/nvs-code/nvs-code/common/temp/node_modules/.pnpm/@tanstack+react-query@4.29.19_biqbaboplfbrettd7655fr4n2y/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/home/runner/_work/nvs-code/nvs-code/common/temp/node_modules/.pnpm/@tanstack+react-query@4.29.19_biqbaboplfbrettd7655fr4n2y/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/_work/nvs-code/nvs-code/web/app/components/RootProviders.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/nvs-code/nvs-code/web/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/nvs-code/nvs-code/web/styles/toast/scss/main.scss");
